// Quasar SCSS (& Sass) Variables
// --------------------------------------------------
// To customize the look and feel of this app, you can override
// the Sass/SCSS variables found in Quasar's source Sass/SCSS files.

// Check documentation for full list of Quasar variables

// Your own variables (that are declared here) and Quasar's own
// ones will be available out of the box in your .vue/.scss/.sass files

// It's highly recommended to change the default colors
// to match your app's branding.
// Tip: Use the "Theme Builder" on Quasar's documentation website.

$primary: #ff900d;
$secondary: #26a69a;
$accent: #9c27b0;

$textbtn: #d3d3d3;

$dark: #1d1d1d;
$dark-page: #121212;

$positive: #21ba45;
$negative: #c10015;
$info: #31ccec;
$warning: #f2c037;

$generic-border-radius: 15px;
